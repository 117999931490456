import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'

const SupplyServicesSection = ({ services }) => (
  <div className="row mb-5 pt-5 justify-content-between">
    {services.map((service) => (
      <div
        className="col-lg-2 col-sm-6 p-1 m-1 d-flex flex-column align-items-center text-center border-purple"
        key={service.icon}>
        <i className={`${service.icon} font-size-8 text-grey-7 shadow-lg rounded p-4`}></i>
        <h5 className="mt-4 mb-0 pb-0 text-grey-8 w-100">{service.title}</h5>
      </div>
    ))}
  </div>
)

export default (props) => {
  const {
    pageTitle,
    subtitle: { subtitle },
    section1title,
    section1Content: { section1Content },
    section1List,
    section2Content,
    section2Image,
    section3title,
    section3content,
    section3video,
    section3list,
    section5title,
    section5list,
  } = props.data.allContentfulPageServicesModal.nodes[0]

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-4 pb-5">
          <PageSubtitle subtitle={subtitle} />
          <div className="row mt-5">
            <div className="col-md-12">
              <h2 className="text-purple font-weight-bold">{section1title}</h2>
              <p>{section1Content}</p>
              <SupplyServicesSection services={section1List} />
            </div>
          </div>
          <div className="row justify-content-center align-content-center">
            <div className="col-md-12 mt-4 text-center">
              <img
                src={`https:${section2Image.file.url}`}
                className="rounded-lg responsive-img"
                alt={'Industries we serve'}
              />
            </div>
            <div className="col-md-12 text-center">
              <p className="pt-3">{section2Content}</p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="row mt-1">
            <div className="col-md-12">
              <h2 className="text-purple font-weight-bold">{section3title}</h2>
              <p>{section3content}</p>
              <div className="row justify-content-between mtt-4">
                <div className="col-md-4 align-self-center">
                  <ul className="pl-0">
                    {section3list.map((section3ListItem) => (
                      <li
                        className="border rounded my-4 list-unstyled p-2 d-flex background-white-pure"
                        key={section3ListItem.title}>
                        <i className="fas fa-star font-size-5 pl-2 pr-4 text-purple" />
                        {section3ListItem.title}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-6 offset-md-1 align-self-center text-right">
                  <video playsInline autoPlay muted loop className="responsive-img">
                    <source src={`https:${section3video.file.url}`} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container mbb-3">
          <div className="row text-center mtt-6">
            <div className="col">
              <h4 className="text-center d-block">{section5title}</h4>
            </div>
          </div>
          <div className="row mtt-2 justify-content-center align-content-center align-items-start px-2">
            {section5list.map((section5listitem) => (
              <div className="col-sm-6 col-lg-3 my-2" key={section5listitem.title}>
                <div className="card border-0">
                  <img
                    src={`https:${section5listitem.image.file.url}`}
                    className="card-img-top border rounded"
                    alt={section5listitem.title}
                    height={'150'}
                  />
                  <div className="card-body">
                    <p className="">{section5listitem.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllServicesQuery {
    allContentfulPageServicesModal {
      nodes {
        pageTitle
        subtitle {
          subtitle
        }
        section1title
        section1Content {
          section1Content
        }
        section1List {
          icon
          title
        }
        section2Content
        section2Image {
          file {
            url
          }
        }
        section3title
        section3content
        section3video {
          file {
            url
          }
        }
        section3list {
          title
        }
        section5title
        section5list {
          title
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`
